/* Do not edit.  Compiled with ./scripts/compile-idl-js from candid/owners.did */
export const idlFactory = ({ IDL }) => {
  const OwnerId = IDL.Nat;
  const OwnersIngressAPI = IDL.Service({
    get: IDL.Func([OwnerId], [IDL.Principal], ['query']),
    lookup: IDL.Func([IDL.Principal], [IDL.Opt(OwnerId)], ['query']),
  });
  return OwnersIngressAPI;
};
export const init = ({ IDL }) => {
  return [];
};
