import { Principal } from '@dfinity/principal';
import { _SERVICE as MultiMinterAPI } from './candid/multi_minter';
import { idlFactory as multiMinterIDLFactory } from './candid/multi_minter.idl';
import { Delegate } from '@research-ag/hpl-client';


export class MultiMinterDelegate extends Delegate<MultiMinterAPI> {
  constructor(protected readonly _canisterPrincipal: Principal | string, network: 'ic' | 'local') {
    super(multiMinterIDLFactory, _canisterPrincipal, network);
  }

  async tokenInfo(assetId: number): Promise<{ symbol: string, assetId: bigint, exchangeRatio: [bigint, bigint], icrc1Ledger: Principal, icrc1Decimals: number, hplDecimals: number  }> {
    return this.resQuery((await this.service).tokenInfo, {}, BigInt(assetId));
  }

  async assetId(icrc1Ledger: Principal | string): Promise<BigInt> {
    const p = icrc1Ledger instanceof Principal ? icrc1Ledger : Principal.fromText(icrc1Ledger);
    return this.resQuery((await this.service).assetId, {}, p);
  }

  async registerFt(icrc1Ledger: Principal | string, decimals: number, description: string): Promise<{
    symbol: string,
    assetId: bigint,
    icrc1Decimals: number,
    hplDecimals: number,
    exchangeRatio: [bigint, bigint],
    icrc1Ledger: Principal
  }> {
    const icrc1 = icrc1Ledger instanceof Principal ? icrc1Ledger : Principal.fromText(icrc1Ledger);
    return this.resUpdate((await this.service).registerFt, icrc1, decimals, description);
  }

  async principalToSubaccount(p: Principal | string): Promise<Uint8Array | number[] | null> {
    const principal = p instanceof Principal ? p : Principal.fromText(p);
    return this.optQuery((await this.service).principalToSubaccount, {}, principal);
  }

  async usableBalance(assetId: number, p: Principal | string): Promise<BigInt> {
    const principal = p instanceof Principal ? p : Principal.fromText(p);
    return this.resQuery((await this.service).usableBalance, {}, BigInt(assetId), principal);
  }

  async notify(assetId: number, p: Principal | string): Promise<{ balanceDelta: BigInt, usableBalance: BigInt } | null> {
    const principal = p instanceof Principal ? p : Principal.fromText(p);
    const res = await this.update((await this.service).notify, BigInt(assetId), principal);
    if (res.length === 0) {
      return null;
    }
    const [balanceDelta, usableBalance] = res[0];
    return { balanceDelta, usableBalance };
  }

  async mint(assetId: number, toAccount: [Principal | string, number | BigInt], mintAmount: number | BigInt | 'max'): Promise<bigint> {
    const dest: [Principal, bigint] = [
      toAccount[0] instanceof Principal ? toAccount[0] : Principal.fromText(toAccount[0]),
      typeof toAccount[1] === 'bigint' ? toAccount[1] : BigInt(toAccount[1] as number),
    ];
    const amount: { max: null } | { amount: bigint } = mintAmount === 'max'
      ? { max: null }
      : { amount: typeof mintAmount === 'bigint' ? mintAmount : BigInt(mintAmount as number) };
    return this.resUpdate((await this.service).mint, BigInt(assetId), dest, amount);
  }

  async burnAndWithdraw(assetId: number, burnAccount: number | BigInt, burnAmount: number | BigInt, recipient: [Principal | string, number[] | null]): Promise<[transactionIndex: bigint, withdrawn: bigint]> {
    return this.resUpdate(
      (await this.service).burnAndWithdraw,
      BigInt(assetId),
      typeof burnAccount === 'bigint' ? burnAccount : BigInt(burnAccount as number),
      typeof burnAmount === 'bigint' ? burnAmount : BigInt(burnAmount as number),
      [
        recipient[0] instanceof Principal ? recipient[0] : Principal.fromText(recipient[0]),
        recipient[1] ? [recipient[1]] : []
      ],
    );
  }

  async withdrawCredit(assetId: number, recipient: [Principal | string, number[] | null]): Promise<[transactionIndex: bigint, withdrawn: bigint]> {
    return this.resUpdate(
      (await this.service).withdrawCredit,
      BigInt(assetId),
      [
        recipient[0] instanceof Principal ? recipient[0] : Principal.fromText(recipient[0]),
        recipient[1] ? [recipient[1]] : []
      ],
    );
  }
}
