/* Do not edit.  Compiled with ./scripts/compile-idl-js from candid/multi_minter.did */
export const idlFactory = ({ IDL }) => {
  const AssetId = IDL.Nat;
  const AssetId_Result = IDL.Variant({
    ok: AssetId,
    err: IDL.Variant({ UnknownToken: IDL.Null }),
  });
  const VirtualAccountId = IDL.Nat;
  const BurnResult = IDL.Variant({
    ok: IDL.Tuple(IDL.Nat, IDL.Nat),
    err: IDL.Variant({
      IcrcTemporarilyUnavailable: IDL.Null,
      IcrcInsufficientFunds: IDL.Null,
      IcrcGenericError: IDL.Null,
      DeletedVirtualAccount: IDL.Null,
      UnknownPrincipal: IDL.Null,
      MismatchInRemotePrincipal: IDL.Null,
      MismatchInAsset: IDL.Null,
      CallLedgerError: IDL.Text,
      SuspendedVirtualAccount: IDL.Null,
      TooLowQuantity: IDL.Null,
      CallIcrc1LedgerError: IDL.Null,
      UnknownVirtualAccount: IDL.Null,
      InsufficientFunds: IDL.Null,
      UnknownToken: IDL.Null,
    }),
  });
  const MintResult = IDL.Variant({
    ok: IDL.Nat,
    err: IDL.Variant({
      DeletedVirtualAccount: IDL.Null,
      UnknownPrincipal: IDL.Null,
      MismatchInRemotePrincipal: IDL.Null,
      MismatchInAsset: IDL.Null,
      InsufficientBalance: IDL.Null,
      CallLedgerError: IDL.Text,
      SuspendedVirtualAccount: IDL.Null,
      UnknownVirtualAccount: IDL.Null,
      UnknownToken: IDL.Null,
    }),
  });
  const ExchangeRatio = IDL.Tuple(IDL.Nat, IDL.Nat);
  const SharedMintTokenData = IDL.Record({
    icrc1Ledger: IDL.Principal,
    icrc1Decimals: IDL.Nat8,
    assetId: AssetId,
    exchangeRatio: ExchangeRatio,
    hplDecimals: IDL.Nat8,
    symbol: IDL.Text,
  });
  const RegisterFt_Result = IDL.Variant({
    ok: SharedMintTokenData,
    err: IDL.Variant({
      NoSpace: IDL.Null,
      FeeError: IDL.Null,
      CallLedgerError: IDL.Text,
      AlreadyExists: IDL.Null,
    }),
  });
  const TokenInfo_Result = IDL.Variant({
    ok: SharedMintTokenData,
    err: IDL.Opt(IDL.Variant({ UnknownToken: IDL.Null })),
  });
  const UsableBalance_Result = IDL.Variant({
    ok: IDL.Int,
    err: IDL.Opt(IDL.Variant({ UnknownToken: IDL.Null })),
  });
  const WithdrawCreditResult = IDL.Variant({
    ok: IDL.Tuple(IDL.Nat, IDL.Nat),
    err: IDL.Variant({
      IcrcTemporarilyUnavailable: IDL.Null,
      IcrcInsufficientFunds: IDL.Null,
      IcrcGenericError: IDL.Null,
      NoCredit: IDL.Null,
      CallIcrc1LedgerError: IDL.Null,
      UnknownToken: IDL.Null,
    }),
  });
  return IDL.Service({
    assetId: IDL.Func([IDL.Principal], [AssetId_Result], ['query']),
    burnAndWithdraw: IDL.Func(
      [AssetId, VirtualAccountId, IDL.Nat, IDL.Tuple(IDL.Principal, IDL.Opt(IDL.Vec(IDL.Nat8)))],
      [BurnResult],
      [],
    ),
    init: IDL.Func([], [], []),
    mint: IDL.Func(
      [AssetId, IDL.Tuple(IDL.Principal, VirtualAccountId), IDL.Variant({ max: IDL.Null, amount: IDL.Nat })],
      [MintResult],
      [],
    ),
    notify: IDL.Func([AssetId, IDL.Principal], [IDL.Opt(IDL.Tuple(IDL.Nat, IDL.Int))], []),
    principalToSubaccount: IDL.Func([IDL.Principal], [IDL.Opt(IDL.Vec(IDL.Nat8))], ['query']),
    registerFt: IDL.Func([IDL.Principal, IDL.Nat8, IDL.Text], [RegisterFt_Result], []),
    tokenInfo: IDL.Func([AssetId], [TokenInfo_Result], ['query']),
    usableBalance: IDL.Func([AssetId, IDL.Principal], [UsableBalance_Result], ['query']),
    withdrawCredit: IDL.Func(
      [AssetId, IDL.Tuple(IDL.Principal, IDL.Opt(IDL.Vec(IDL.Nat8)))],
      [WithdrawCreditResult],
      [],
    ),
  });
};
export const init = ({ IDL }) => {
  return [IDL.Opt(IDL.Principal), IDL.Opt(IDL.Principal)];
};
