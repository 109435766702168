/* Do not edit.  Compiled with ./scripts/compile-idl-js from candid/aggregator.did */
export const idlFactory = ({ IDL }) => {
  const StreamStatus = IDL.Record({
    id: IDL.Nat,
    sent: IDL.Nat,
    length: IDL.Nat,
    received: IDL.Nat,
  });
  const SubId = IDL.Nat;
  const VirId = IDL.Nat;
  const RemoteId = IDL.Tuple(IDL.Principal, VirId);
  const AccountRef = IDL.Variant({
    sub: SubId,
    vir: RemoteId,
    mint: IDL.Null,
  });
  const AssetId = IDL.Nat;
  const FtTransfer = IDL.Record({
    to: AccountRef,
    asset: AssetId,
    from: AccountRef,
    memo: IDL.Vec(IDL.Vec(IDL.Nat8)),
    feeMode: IDL.Opt(IDL.Variant({ receiverPays: IDL.Null, senderPays: IDL.Null })),
    amount: IDL.Variant({ max: IDL.Null, amount: IDL.Nat }),
  });
  const TxInput = IDL.Variant({ ftTransfer: FtTransfer });
  const GlobalId = IDL.Tuple(IDL.Nat, IDL.Nat);
  const Time = IDL.Nat64;
  const GidStatus = IDL.Variant({
    pending: IDL.Record({}),
    other: IDL.Tuple(Time, IDL.Variant({ settled: IDL.Null, settledIfIssuedHere: IDL.Null })),
    queued: IDL.Tuple(IDL.Nat),
  });
  const AggregatorIngressAPI = IDL.Service({
    streamStatus: IDL.Func([], [IDL.Vec(StreamStatus)], ['query']),
    submitAndExecute: IDL.Func([IDL.Vec(TxInput)], [IDL.Vec(GlobalId)], []),
    txStatus: IDL.Func([IDL.Vec(GlobalId)], [IDL.Vec(GidStatus)], ['query']),
  });
  return AggregatorIngressAPI;
};
export const init = ({ IDL }) => {
  return [];
};
