/* Do not edit.  Compiled with ./scripts/compile-idl-js from candid/ledger_admin.did */
export const idlFactory = ({ IDL }) => {
  const IdRange = IDL.Tuple(IDL.Nat, IDL.Opt(IDL.Nat));
  const IdSelector = IDL.Variant({
    id: IDL.Nat,
    cat: IDL.Vec(IDL.Variant({ id: IDL.Nat, idRange: IdRange })),
    idRange: IdRange,
  });
  const SubId = IDL.Nat;
  const AssetId = IDL.Nat;
  const AccountType = IDL.Variant({ ft: AssetId });
  const VirId = IDL.Nat;
  const RemoteId = IDL.Tuple(IDL.Principal, VirId);
  const RemoteIdRange = IDL.Tuple(IDL.Principal, IDL.Nat, IDL.Opt(IDL.Nat));
  const RemoteSelector = IDL.Variant({
    id: RemoteId,
    cat: IDL.Vec(IDL.Variant({ id: RemoteId, idRange: RemoteIdRange })),
    idRange: RemoteIdRange,
  });
  const FtSupply = IDL.Nat;
  const AccountState = IDL.Variant({ ft: IDL.Nat });
  const Time = IDL.Nat64;
  const LedgerAdminAPI = IDL.Service({
    adminAccountInfo: IDL.Func([IdSelector], [IDL.Vec(IDL.Tuple(SubId, AccountType))], ['query']),
    adminState: IDL.Func(
      [
        IDL.Record({
          ftSupplies: IDL.Opt(IdSelector),
          virtualAccounts: IDL.Opt(IdSelector),
          accounts: IDL.Opt(IdSelector),
          remoteAccounts: IDL.Opt(RemoteSelector),
        }),
      ],
      [
        IDL.Record({
          ftSupplies: IDL.Vec(IDL.Tuple(AssetId, FtSupply)),
          virtualAccounts: IDL.Vec(IDL.Tuple(VirId, IDL.Tuple(AccountState, SubId, Time))),
          accounts: IDL.Vec(IDL.Tuple(SubId, AccountState)),
          remoteAccounts: IDL.Vec(IDL.Tuple(RemoteId, IDL.Tuple(AccountState, Time))),
        }),
      ],
      ['query'],
    ),
    nAdminAccounts: IDL.Func([], [IDL.Nat], ['query']),
  });
  return LedgerAdminAPI;
};
export const init = ({ IDL }) => {
  return [];
};
